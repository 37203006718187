.mentions {
  margin: 2em 15px;
}
.mentions--singleLine .mentions__control {
  display: inline-block;
  padding-left: 10px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  border: 2px inset grey;
  font-style: italic;
  padding-left: 10px;
}

.mentions--multiLine .mentions__control {
  font-family: OpenSansRegular, Helvetica, sans-serif;
  font-size: 12pt;
  font-weight: 400;
  line-height: 1.43;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 8px;
  min-height: 150px;
  line-height: normal;
  /* visibility: hidden; */
}
.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
  border-radius: 5px;
}

.mentions--multiLine .mentions__input:focus {
  border-color: #26890D;
}
.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  min-width: 250px;
  max-height: 200px;
  overflow-x: auto;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}


.mentions__suggestions__item {
  padding: 5px 15px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
  min-height: 35px;
}
.mentions__suggestions__item--focused {
  background-color: rgba(0, 0, 0, 0.08) !important;
  color: #070707;
  min-height: 35px;
}
.mentions__mention {
  position: relative;
  z-index: 1;
  color:  #2200CC;
  font-style: italic;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: none;
  pointer-events: none;
  font-weight: bold;
  text-decoration: underline;
}
