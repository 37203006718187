::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
    margin: 0 30px;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}